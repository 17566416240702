import { Component, OnInit } from '@angular/core';
import { CdkDragDrop , moveItemInArray} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../services/api.service';
import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';

import * as moment from 'moment';
import * as _ from 'lodash';
import { ConfirmUpdateRulesDialog } from './confirm-update-rules/confirm-update-rules-dialog.component';
import { map } from 'rxjs/operators';
//import { timer } from 'rxjs';

@Component({
    selector: 'app-code-review-rule',
    templateUrl: './code-review-rule.component.html',
    styleUrls: ['./code-review-rule.component.scss']

})
export class CodeReviewRuleComponent implements OnInit {
    finClasses = [];
    physicians:any = [];
   
    ruleName: string; 
    ruleNameOld: string;
    currentCodeReviewRule: any;
    codeReviewRules: any = [];
    codeReviewRuleUsers: any = [];
    ruleList: any = [];
    ruleOwnList: any = [];
  
    isShowAlertDelete: boolean = false;
    isShowMessage: boolean = false;
    isShowRuleCotent: boolean = false;
    codeReviewRuleDetail: any;
    stringMsg = ""
    isEdit: boolean = false;
    ruleActive: boolean = false;
    bulkActionSets = []
    selectedBulkAction: any;
    selectedSetId = null
    isSaveSorting: boolean = false;
    isActiveAllRule: boolean = false;
    ocrSections: any = [];
    ocrVitalsSections: any = [];
    ocrOrderProceduresSections: any = [];
    ocrReviewOfSysSections: any = [];
    emrId = null;

    // codeReviewDetails = [
    //     {
    //         id: null,
    //         operatorTargetName: "",
    //         operatorTargetValue: "",
    //         operator: "",
    //         logicOperator: "",
    //         isDisableLogic: true,
    //         active: false
    //     }
    // ]
    
    groupCodeReview: any = [
        {
            id: null,
            logicOperator: "",  
            ruleName: "",
            ruleNameOld: "",
            actionSetId : null,
            active: false,
            ruleList: [
                {
                    id: null,
                    operatorTargetName: "",
                    chartOcrSectionId: "",
                    operatorTargetValue: "",
                    operatorTargetValue_2: "",
                    operator: "",
                    logicOperator: "",
                    isDisableLogic: true,
                    active: false
                }
            ]
        }
    ]

    listVisits: any = []
    loadingTableVisit: boolean = false;
    userId: string = "";
    
    private dialogLoading: any;
    clinics: any = []
    constructor(
        private apiService: ApiService,
        private matDialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) {
        this.userId = localStorage.getItem('userId')
    }

    ngOnInit(): void {
        this.getFinClass()
        this.getPhysicians()
        this.getCodeReviewRule()
        this.getAllBulkActionSet();
        this.getClinics();
        this.getChartOcrSections();
        this.getChartOcrVitalsSections();
        this.getChartOcrReviewOfSysSections();
        this.getChartOrderProceduresSections();
        this.getUserEmrId();
        //this.getCodeReviewRuleUser()


    }

    getAllBulkActionSet() {
        this.apiService.getAllBulkActionSet().subscribe((r) => {
            this.bulkActionSets = r || []
        })
    }

    changeBulkAction() {
        this.selectedSetId = this.selectedBulkAction ? JSON.parse(this.selectedBulkAction.id) : null
    }

    getFinClass() {
        this.apiService.getFinClasses().subscribe((r) => {
            this.finClasses = r;
            this.finClasses = _.orderBy(this.finClasses, 'text')
        });
    }

    getPhysicians(){
        this.apiService.getListPhysicians().subscribe((r:any) => {
            this.physicians = r
        })
    }

    getClinics() {
        this.apiService.getClinics().subscribe(results => {
            this.clinics = _.orderBy(_.uniqBy(results, 'id'), 'id');
        })
    }

    getChartOcrSections() {
        this.apiService.getChartOcrSections().subscribe((r: any) => {
            this.ocrSections = r
        })
    }

    getChartOcrVitalsSections() {
        this.apiService.getChartOcrVitalsSections().subscribe((r: any) => {
            this.ocrVitalsSections = r
        })
    }
    getChartOrderProceduresSections() {
        this.apiService.getChartOrderProceduresSections().subscribe((r: any) => {
            this.ocrOrderProceduresSections = r
        })
    }
    
    getChartOcrReviewOfSysSections() {
        this.apiService.getChartOcrReviewOfSysSections().subscribe((r: any) => {
            this.ocrReviewOfSysSections = r
            console.log(this.ocrReviewOfSysSections)
        })
    }
    //getCodeReviewRuleUser() {
    //    this.apiService.getCodeReviewRuleGroupUser().subscribe((res) => {
    //        this.codeReviewRuleUsers = res || []
    //        let rules = []
    //        _.each(res, (e, ind) => {
    //            let itemRule = {
    //                ruleName: e.ruleName,
    //                active: e.active
    //            }
    //            rules.push(itemRule)
    //        })
    //        this.ruleOwnList = _.uniqBy(rules, 'ruleName')
    //    });
    //}

    getUserEmrId() {
        this.apiService.getUserEmrId().subscribe((res) => {
            this.emrId = res || 0
            console.log("ecmr id " + this.emrId);
        })
    }

    getCodeReviewRule(ruleName = "") {
        this.apiService.getCodeReviewRule().subscribe((res) => {
            
            this.codeReviewRules = res || []
            let rules = []
            _.each(this.codeReviewRules, (e, ind) => {
                let itemRule = {
                    id: e.id,
                    ruleName: e.ruleName,
                    active: e.active,
                    isEdit: false,
                    hasActionSet: e.hasActionSet,
                    orderIndex: e.orderIndex
                }
                rules.push(itemRule)
            })
            this.ruleList = _.uniqBy(rules, 'ruleName');
            this.checkActiveAll();

            if (ruleName && ruleName != '') {
                this.editRule(ruleName);
            }
        })
    }

    addNewRule() {
        this.ruleNameOld = "";
        this.isShowRuleCotent = true
        this.isEdit = false
        this.cleanRuleTable();
    }

    cleanRuleTable() {
        this.ruleName = "";
        this.listVisits = [];
        let lastOrderIndex = _.orderBy(this.codeReviewRules, ['orderIndex'], ['desc'])[0]?.orderIndex
        this.groupCodeReview = [
            {
                id: null,
                logicOperator: "",
                ruleName: "",
                ruleNameOld: "",
                active: false,
                ruleList: [
                    {
                        id: null,
                        operatorTargetName: "",
                        chartOcrSectionId: "",
                        operatorTargetValue: "",
                        operatorTargetValue_2: "",
                        operator: "",
                        logicOperator: "",
                        isDisableLogic: true,
                        active: false,
                        orderIndex: lastOrderIndex + 1
                    }
                ]
            }
        ]
    }

    addGroup(){
        let orderIndex = 0
        if(this.groupCodeReview?.length > 0 && this.groupCodeReview[0].ruleList?.length > 0){
            orderIndex = this.groupCodeReview[0].ruleList[0].orderIndex
        }
        var item =   {
            id: null,
            logicOperator: "",
            ruleName: "",
            ruleNameOld: "",
            active: false,
            ruleList: [
                {
                    id: null,
                    operatorTargetName: "",
                    operatorTargetValue: "",
                    operator: "",
                    logicOperator: "",
                    isDisableLogic: true,
                    active: false,
                    orderIndex: orderIndex
                }
            ]
        }
        this.groupCodeReview.push(item)
    }

    async saveRuleTable() {
        this.isShowMessage = false
        let ruleListOperatorEmpty = []
        let ruleListEmpty = []
        let groupOperatorEmpty = _.filter(this.groupCodeReview, (e, index) => {
            return e.logicOperator === '' && index != this.groupCodeReview.length - 1 && this.groupCodeReview.length > 1
        })
        //save the action set is selected

     

        _.each(this.groupCodeReview, (e, index) => {
            _.each(e.ruleList, (rule, indR) => {

                if (rule.operator === '' || rule.operatorTargetName === ''

                    || ((rule.operatorTargetName === 'ChartText' || rule.operatorTargetName === 'ChartVitals' ) && rule.chartOcrSectionId === '') || rule.operatorTargetValue == '') {
                    console.log(rule)
                    ruleListEmpty.push(rule)
                }
                if(rule.logicOperator === '' && e.ruleList.length > 1 && e.ruleList.length - 1 !== indR){
                    ruleListOperatorEmpty.push(rule)
                }
            })
        })
        if(groupOperatorEmpty?.length > 0 || ruleListOperatorEmpty.length > 0){
            this.isShowMessage = true
            this.stringMsg = "Please enter logic operator"
            return 
        }
        if(ruleListEmpty.length > 0){
            console.log(ruleListEmpty)
            this.isShowMessage = true
            this.stringMsg = "Please enter Field, Operator, Value"
            return 
        }
        if(!this.ruleName || this.ruleName.trim() == ''){
            this.isShowMessage = true
            this.stringMsg = "Please enter RuleName"
            return 
        }
        let ruleNameExisting = _.find(this.ruleList, (e) => { return e.ruleName.toLowerCase() == this.ruleName.toLowerCase() && !e.isEdit})
        if (ruleNameExisting) {
            this.isShowMessage = true
            this.stringMsg = "Same rule names alreasy exists."
            return
        }
        this.openLoading()
        try { await this.apiService.updateCodeReviewRuleById(this.currentCodeReviewRule).toPromise() } catch { };
        let groupCodesSave = _.cloneDeep(this.groupCodeReview)
        _.each(groupCodesSave, (g) => {
            _.each(g.ruleList, (e, ind) => {
                if (e.operatorTargetName == 'FinClass' && e.operator == 'In') {
                    e.operatorTargetValue = JSON.stringify(e.operatorTargetValue)
                }
                if (e.operatorTargetName == 'FinClass' && e.operator != 'In') {
                    e.operatorTargetValue = e.operatorTargetValue.toString()
                }
                e.active = this.ruleActive
            })
            g.ruleName = this.ruleName,
            g.ruleNameOld = this.ruleNameOld
            g.active = this.ruleActive
            g.actionSetId = this.selectedSetId 
        })
        if (groupCodesSave.length > 0) {
            this.apiService.saveCodeReviewRuleNew(groupCodesSave).subscribe( (r) => {
                if (r.success) {
                    //this.closeLoading()
                    //location.reload();

                    //if (groupCodesSave[0].ruleName) {
                    //    _.each(groupCodesSave[0].ruleList, rule => {
                    //        const index = _.findIndex(this.codeReviewRules, item => item.id == rule.id);
                    //        if (index !== -1) {
                    //            this.codeReviewRules[index] = rule;
                    //            this.codeReviewRules[index].ruleName = groupCodesSave[0].ruleName;
                    //        }
                    //    })
                    //    this.editRule(groupCodesSave[0].ruleName)
                    //} else {
                    //    this.closeLoading()
                    //    location.reload();
                    //}
                    if (groupCodesSave[0].ruleName) {
                        this.getCodeReviewRule(groupCodesSave[0].ruleName)
                    }

                } else {
                    this.isShowMessage = true
                }
            })
        }
    }

    deleteRule(ruleName) {
        if (confirm('You are about to delete a rule, are you sure?')) {
            this.openLoading()
            this.apiService.deleteRuleByRuleName(ruleName).subscribe((r) => {
                if (r.success) {
                    this.closeLoading()
                    this.codeReviewRules = this.codeReviewRules.filter((r: any) => { return r.ruleName != ruleName })
                    this.ruleList = this.ruleList.filter((r: any) => { return r.ruleName != ruleName })
                    this.ruleOwnList = this.ruleOwnList.filter((r: any) => { return r.ruleName != ruleName })
                    if (r.last) {
                        this.isShowAlertDelete = true;
                    } 
                    this.showSnackBar(r.message)
                } else {
                    alert('Failed to delete this rule.');
                }
            })
        }
    }

    resetActionSetSelection() {
        this.selectedBulkAction = null
        this.selectedSetId = null
    }

    editRule(ruleName) {
        this.resetActionSetSelection();
        if (!this.dialogLoading) {
            this.openLoading()
        }
        this.isEdit = true
        this.listVisits = []
        let ruleDetails = _.filter(this.codeReviewRules, (e) => { return e.ruleName == ruleName })
        _.each(this.ruleList, (e) => {
            if (e.ruleName.toLowerCase() == ruleName.toLowerCase()) {
                e.isEdit = true
            } else {
                e.isEdit = false
            }
        })

        let ruleId = ruleDetails[0].id
        this.ruleName = ruleDetails[0].ruleName
        this.ruleActive = ruleDetails[0].active
        this.ruleNameOld = _.cloneDeep(ruleDetails[0].ruleName)
        let codeReviewDetails = _.map(ruleDetails, (e) => {
            return {
                id: e.id,
                operatorTargetName: e.operatorTargetName,
                operatorTargetValue: e.operatorTargetValue,
                operatorTargetValue_2: e.operatorTargetValue_2,
                operator: e.operator,
                logicOperator: e.logicOperator,
                isDisableLogic: false,
                active: e.active,
                orderIndex: e.orderIndex,
                chartOcrSectionId: e.chartOcrSectionId
            }
        })
        _.each(codeReviewDetails, (e, ind) => {
            if (ind == codeReviewDetails.length - 1) {
                    e.isDisableLogic = true
            }
            if (e.operatorTargetName == 'FinClass' && e.operator == 'In') {
                //e.operatorTargetValue = parseInt(e.operatorTargetValue)
                e.operatorTargetValue = JSON.parse(e.operatorTargetValue)
            }
            if ((e.operatorTargetName == 'FinClass' && e.operator != 'In') || e.operatorTargetName == "Physician") {
                e.operatorTargetValue = parseInt(e.operatorTargetValue)
            }
        })
        this.apiService.getGroupCodeReviewRule(this.ruleName).subscribe((r) => {
            if(r?.length > 0){
               let groupCodes = r;
               _.each(groupCodes, (e) => {
                  let ruleIds =  e.ruleIdJson?.length > 0 ? JSON.parse(e.ruleIdJson) : []
                  e.ruleNameOld = this.ruleNameOld
                  let ruleDetail = _.filter(codeReviewDetails, (e) => {return _.includes(ruleIds, e.id)})
                  e.ruleList = ruleDetail
               })
               this.groupCodeReview = groupCodes
            }else{
                this.groupCodeReview = [
                    {
                        id: null,
                        logicOperator: "",  
                        ruleName: "",
                        ruleNameOld: "",
                        active: false,
                        ruleList: codeReviewDetails
                    }
                ]
            }
            this.isShowRuleCotent = true;
        })
        this.apiService.getCodeReviewRuleActionSet(ruleId).subscribe((s) => {
            if (s.length > 0) {
                this.selectedBulkAction = _.filter(this.bulkActionSets, (e) => { return e.id == s[0].actionSetId })[0]
                this.selectedSetId = this.selectedBulkAction ? JSON.parse(this.selectedBulkAction.id) : null
            }

            this.closeLoading()

        })

        this.apiService.getCodeReviewRuleById(ruleId).subscribe((r) => {
            this.currentCodeReviewRule = r

        })
    }

    activeRule(rule, active) {
        // let ruleDetails = _.filter(this.codeReviewRules, (e) => { return e.ruleName == rule.ruleName })
        // let ruleIds = _.map(ruleDetails, 'id')
        this.openLoading()
        this.apiService.setActiveGroupAndRuleItemByRuleName(rule.ruleName, active).subscribe((r) => {
            if (r.success) {
                if (active) {
                    this.showSnackBar('This rule is activated')
                } else {
                    this.showSnackBar('This rule is deactivates')
                }
            }
            this.closeLoading()
        })
    }

    addRow() {
        _.each(this.groupCodeReview, (e, index) => {
            if(index == this.groupCodeReview.length - 1){
                var item = {
                    id: null,
                    operatorTargetName: "",
                    operatorTargetValue: "",
                    operatorTargetValue_2: "",
                    operator: "",
                    logicOperator: "",
                    isDisableLogic: true,
                    active: true
                }
                e.ruleList.push(item)
            }
        })
    }

    addRowIndex(indexGroup, indexItem, rowData){
        var item = {
            id: null,
            operatorTargetName: "",
            operatorTargetValue: "",
            operatorTargetValue_2 :"",
            operator: "",
            logicOperator: "",
            isDisableLogic: true,
            active: true, 
            orderIndex: rowData.orderIndex
        }
        _.each(this.groupCodeReview, (e, indG) => {
          if(indG == indexGroup){
             e.ruleList.splice(indexItem + 1, 0, item)
          }
       })
    }

    deleteRow(indexGroup, indexItem, groupRule, rule) {
        if (rule.id != null && groupRule.id != null) {
            this.openLoading()
            if (confirm('You are about to delete a rule, are you sure?')) {
                this.apiService.removeGroupAndItemRule(rule.id, groupRule.id).subscribe((r) => {
                    if (r.success) {
                        this.removeGroupAndRuleItem(indexGroup, indexItem)
                        this.closeLoading()
                    } else {
                        alert('Failed to delete this rule.');
                        this.closeLoading()
                    }
                })
            }
        } else if(rule.id != null && groupRule.id == null){
            if (confirm('You are about to delete a rule, are you sure?')) {
                this.apiService.deleteCodeReviewRule(rule.id).subscribe((r) => {
                    if (r.success) {
                        this.removeGroupAndRuleItem(indexGroup, indexItem)
                        this.closeLoading()
                    } else {
                        alert('Failed to delete this rule.');
                        this.closeLoading()
                    }
                })
            }
        }else {
            this.removeGroupAndRuleItem(indexGroup, indexItem)
        }
    }

    removeGroupAndRuleItem(indexGroup, indexItem){
       let isRemoveGroupItem = false;
        _.each(this.groupCodeReview, (e, index) => {
           if(index == indexGroup){
              e.ruleList = _.filter(e.ruleList, (r, i) => {return i != indexItem})
              if(e.ruleList?.length == 0){
                isRemoveGroupItem = true
              }
           }
       })
       if(isRemoveGroupItem){
         this.groupCodeReview = _.filter(this.groupCodeReview, (e, index) => {
            return index != indexGroup
         })
       }
    }

    removeGroup(indexGroup, group){
      if(group.id != null){
        this.openLoading()
        this.apiService.removeGroupCode(group.id).subscribe((r) => {
            if (r.success) {
                this.groupCodeReview = _.filter(this.groupCodeReview, (e, index) => {return index != indexGroup})
                this.closeLoading()
            } else {
                alert('Failed to delete this rule.');
                this.closeLoading()
            }
        })
      }else{
        this.groupCodeReview = _.filter(this.groupCodeReview, (e, index) => {return index != indexGroup})
      }
    }
    
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.ruleList, event.previousIndex, event.currentIndex);
        _.each(this.ruleList, (e, index) => {
            e.orderIndex = index
        })
        _.each(this.codeReviewRuleDetail, (e) => {
            var existingRuleList = _.find(this.ruleList, (rule) => {return rule.ruleName == e.ruleName})
            e.orderIndex = existingRuleList.orderIndex
        })
        if(event.previousIndex != event.currentIndex){
            this.isSaveSorting = true
            this.saveSorting()
        }
        return
        let item = event.container.data[event.previousIndex] as any
        let existing = _.find(this.ruleOwnList, (e) => { return e.ruleName === item.ruleName })
        if (existing) {
            return;
        }
        item.active = false
        this.ruleOwnList.push(item)
        let ruleDetails = _.filter(this.codeReviewRules, (e) => { return e.ruleName == item.ruleName })
        let payload = {
            ruleName: ruleDetails[0].ruleName,
            ruleIds: _.map(ruleDetails, 'id'),
            active: false,
        }
        this.apiService.saveCodeReviewRuleGroupUser(payload).subscribe((r) => {
            if (r.success) {
                //this.getCodeReviewRuleUser()
                this.showSnackBar(r.message)
            }
        })
    }

    //activeRuleOwn(rule, active) {
    //    let ruleDetails = _.filter(this.codeReviewRuleUsers, (e) => { return e.ruleName == rule.ruleName })
    //    let payload = {
    //        ruleName: ruleDetails[0].ruleName,
    //        ruleIds: _.map(ruleDetails, 'id'),
    //        active: active
    //    }
    //    this.apiService.setActiveCodeReviewRuleGroupUser(payload).subscribe((r) => {
    //        if (r.success) {
    //            if (active) {
    //                this.showSnackBar('This rule is activated')
    //            } else {
    //                this.showSnackBar('This rule is deactivates')
    //            }
    //        }
    //    })
    //}

    //removeRuleOwn(rule) {
    //    if (confirm('You are about to delete a rule, are you sure?')) {
    //        this.openLoading()
    //        let ruleDetails = _.filter(this.codeReviewRuleUsers, (e) => { return e.ruleName == rule.ruleName })
    //        if (ruleDetails.length > 0) {
    //            let ruleIds = _.map(ruleDetails, 'id')
    //            this.apiService.removeCodeReviewRuleGroupUser(ruleIds).subscribe((r) => {
    //                if (r.success) {
    //                    this.closeLoading()
    //                    this.ruleOwnList = _.filter(this.ruleOwnList, (e) => { return e.ruleName.toLowerCase() !== ruleDetails[0].ruleName.toLowerCase() })
    //                    this.showSnackBar(r.message)
    //                    this.getCodeReviewRuleUser()
    //                }
    //            })
    //        }
    //    }
    //}

    testRuleVisit(){
        this.loadingTableVisit = true
        let pageSize = 20
        this.apiService.getVisitCheckRuleCode(this.ruleName, pageSize).subscribe((r) => {
            this.loadingTableVisit = false
            this.listVisits = r || []
       })
    }

    saveSorting(){
        this.openLoading()
        let payload = _.map(this.ruleList, (e) => {
           return {
            ruleName: e.ruleName, 
            orderIndex: e.orderIndex
           }
        })
        this.apiService.updateOrderIndexForCodeReviewRule(payload).subscribe((r) => {
            // this.getCodeReviewRule()
            this.isSaveSorting = false
            this.closeLoading()
            this.showSnackBar("The sort update was successful!")
        }, err => {
            this.closeLoading()
            this.showSnackBar("The sort update was unsuccessful!")
        })
    }

    showSnackBar(message) {
        this._snackBar.open(message)
        setTimeout(() => {
            this._snackBar.dismiss()
        }, 1000); 
        
    }

    openLoading() {
       this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
            minWidth: '150px',
            disableClose: true
        });
    }

    closeLoading() {
        this.dialogLoading.close()
    }


    checkActiveAll() {
        this.isActiveAllRule = _.find(this.ruleList, rule => !rule.active) ? false : true;
    }

    changeStateAllRule(event) {
        var dialog = this.matDialog.open(ConfirmUpdateRulesDialog, {
            minWidth: '150px',
            disableClose: true,
            data: event
        });

        dialog.afterClosed().subscribe(async r => {
            this.openLoading()
            if (r) {
                for (let i = 0; i < this.ruleList.length; i++) {
                    const rule = this.ruleList[i];
                    rule.active = this.isActiveAllRule;
                    await this.apiService.setActiveGroupAndRuleItemByRuleName(rule.ruleName, event).toPromise();
                    //await timer(2000).toPromise();
                }
                this.closeLoading();
            } else {
                this.isActiveAllRule = !event;
                this.closeLoading()
            }
        })
    }
   
}
